import React, {useState, useEffect, useContext, Fragment} from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import api from "../../services/api";
import toastError from "../../errors/toastError";
import ContactSelect from "../ContactSelect";
import TagSelect from "../TagSelect";
import {AuthContext} from "../../context/Auth/AuthContext";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {
	TimePicker,
	MuiPickersUtilsProvider,
	KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ConfirmationModal from "../ConfirmationModal";
//import {i18n} from "../../translate/i18n";
import ButtonWithSpinner from "../ButtonWithSpinner";
import TemplateSelect from "../TemplateSelect";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	noDisplay: {
		display: "none"
	},
	textWarning: {
		color: 'red',
		fontStyle: 'italic',
		marginLeft: "5px"
	},
	fieldLegend: {
		marginTop: '15px',
		fontSize: '12pt',
		fontWeight: 'bold'
	}
}));

const EnvioSchema = Yup.object().shape({
	message: Yup.string()
		.min(5, "Mensagem muito curta"),
	// .required("Obrigatório"),
	recurrence: Yup.string()
		.required("Obrigatório"),
	sendAt: Yup.date()
		.required("Obrigatório")
});

const AgendamentoModal = ({ open, onClose, agendamentoId, contactId }) => {
	const classes = useStyles();

	const initialState = {
		title: "",
		message: "",
		recurrence: "once",
		RValue: "",
		sendAt: new Date(),
		enviado: 0,
		total: 0,
		contactIds: [],
		tagIds: [],
		allContacts: false
	};

	const [agendamento, setAgendamento] = useState(initialState);
	const [selectedContactIds, setSelectedContactIds] = useState([]);
	const [selectedTagIds, setSelectedTagIds] = useState([]);
	const [selectedAllContacts, setSelectedAllContacts] = useState(false);
	const [loading, setLoading] = useState(false);
	const [valorR, setValorR] = useState("once");
	const { user } = useContext(AuthContext);
	const now = new Date();

	const [selectedTime, setTime] = useState(new Date(now.getTime() + 5 * 60000));
	const [destAmount, setDestAmount] = useState(0);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [request, setRequest] = useState({});

	const [whatsapps, setWhatsapps] = useState([]);
	const [selectedWhatsapp, setSelectedWhatsapp] = useState("");
	const [selectedTemplateIds, setSelectedTemplateIds] = useState([]);
	const [reenviar, setReenviar] = useState(false);
	const [medias, setMedias] = useState(null);
	const [file, setFile] = useState(null);

	useEffect(() => {
		if (contactId) {
			setSelectedContactIds([contactId])
			setSelectedTagIds([]);
			setSelectedAllContacts(false);
		}
	}, [contactId]);

	useEffect(() => {
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchWhatsapps = async () => {
				try {
					const { data: wpps} = await api.get("/whatsapp/");
					setWhatsapps(wpps);

					wpps.map((wpp) => {
						if (user.whatsapp?.id) {
							if (user.whatsapp.id === wpp.id) setSelectedWhatsapp(wpp);
						} else {
							if (wpp.isDefault) setSelectedWhatsapp(wpp);
						}
						return null;
					})

					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};

			fetchWhatsapps();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [user]);

	useEffect(() => {
		(async () => {
			if (!agendamentoId) return;
			try {
				const { data } = await api.get(`/agendamento/${agendamentoId}`);
				setAgendamento(prevState => {
					return { ...prevState, ...data };
				});
				setValorR(data.recurrence);
				setTime(data.sendAt);
				setSelectedAllContacts(data.allContacts);
				setFile(data.mediaUrl);

				if (data.tagsIds)
					setSelectedTagIds(data.tagsIds.split(",").map(tag => parseInt(tag, 10)));
				if (data.contactsIds)
					setSelectedContactIds(data.contactsIds.split(",").map(ctct => parseInt(ctct, 10)));

			} catch (err) {
				toastError(err);
			}
			return () => {
				setAgendamento({
					title: "",
					message: "",
					recurrence: "once",
					RValue: "",
					sendAt: new Date(),
					enviado: 0,
					total: 0,
					contactIds: [],
					tagIds: [],
					allContacts: false
				});
			};
		})();
	}, [agendamentoId, open]);

	const handleClose = () => {
		onClose();
		setAgendamento({
			title: "",
			message: "",
			recurrence: "once",
			RValue: "",
			sendAt: new Date(),
			enviado: 0,
			total: 0,
			contactIds: [],
			tagIds: [],
			allContacts: false
		});
		setSelectedTagIds([]);
		setSelectedContactIds([]);
		setSelectedAllContacts(false);
		setReenviar(false);
		setFile(null);
	};

	const handleSaveEnvio = async values => {
		let req = {
			title: values.title,
			message: values.message,
			recurrence: valorR,
			sendAt: selectedTime,
			RValue: valorR === "once" && !agendamentoId ? "0" : values.RValue,
			allContacts: selectedAllContacts,
			template: selectedWhatsapp.api === "cloud_api" ? JSON.stringify(selectedTemplateIds?.data) : undefined,
			contactsIds: selectedContactIds,
			whatsappId: selectedWhatsapp.id,
			tagsIds: selectedTagIds,
			log: {
				userId: user?.id,
				userName: user?.name
			},
			media: medias
		};

		if (!req.allContacts && req.contactsIds.length < 1 && req.tagsIds.length < 1) {
			toast.error("Por favor, informe um destinatário para esse agendamento");
			return false;
		}

		if (req.recurrence !== "every_day" && !req.RValue) {
			toast.error("Campo do valor da recorrência não pode estar em branco");
			return false;
		}

		if (req.recurrence === "once" && (!req.sendAt || req.sendAt === null)) {
			toast.error("Data/Horário de envio inválido");
			return false;
		}

		if (req.recurrence === "every_month" && req.RValue.trim().length !== 2) {
			toast.error("Dia inválido");
			return false;
		}

		if (req.recurrence === "quarterly") {
			if (req.RValue.trim().length !== 5) {
				toast.error("Dia e mês inválidos");
				return false;
			}

			let day_month = req.RValue.trim().split("/");

			if (day_month[1] === "02") {
				let now = new Date();
				if (now.getFullYear() % 4 === 0) {
					if (parseInt(day_month[0], 10) > 29) {
						toast.error("Dia inválido");
						return false;
					}
				} else {
					if (parseInt(day_month[0], 10) > 28) {
						toast.error("Dia inválido");
						return false;
					}
				}
			}
		}

		if (req.recurrence === "every_week") {
			if (req.RValue.trim().length < 6 || req.RValue.trim().length > 9) {
				toast.error("Dia da semana inválido");
				return false;
			}
		}

		if (req.sendAt < new Date() && req.recurrence === "once"){
			toast.error("A data e hora de envio não pode ser menor que o horário atual");
			return false;
		}

		if (agendamentoId && reenviar) {
			if (req.recurrence === "once")
				req.RValue = "0";

			req = {
				...req,
				firstSend: false
			}
			// req.firstSend = false;
		}

		if (agendamentoId && !reenviar) {
			if (req.recurrence === "once")
				req.RValue = "1";

			req = {
				...req,
				firstSend: true
			}
		}

		setLoading(true);

		const { data } = await api.get("/agendamento/count", { params: { allContacts: req.allContacts, contactsIds: req.contactsIds.join(","), tagsIds: req.tagsIds.join(",") }});

		setRequest(req);
		setDestAmount(data);
		setConfirmModalOpen(true);
		setLoading(false);
	};

	const handleCloseConfirmationModal = () => {
		setConfirmModalOpen(false);
		setDestAmount(0);
		setRequest({});
	};

	const handleConfirmationCreate = async () => {
		try {
			if (agendamentoId) {
				const formData = new FormData();
				formData.append("title", request.title);
				formData.append("message", request.message);
				formData.append("recurrence", request.recurrence);
				formData.append("sendAt", request.sendAt);
				formData.append("RValue", request.RValue);
				formData.append("allContacts", request.allContacts);
				formData.append("contactsIds", request.contactsIds);
				formData.append("whatsappId", request.whatsappId);
				formData.append("tagsIds", request.tagsIds);
				formData.append("log", request.log);
				formData.append("firstSend", request.firstSend);

				if (medias)
					medias.forEach((media) => {
						formData.append("medias", media);
					});

				await api.put(`/agendamento/${agendamentoId}`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
			} else {
				const formData = new FormData();
				formData.append("title", request.title);
				formData.append("message", request.message);
				formData.append("recurrence", request.recurrence);
				formData.append("sendAt", request.sendAt);
				formData.append("RValue", request.RValue);
				formData.append("allContacts", request.allContacts);
				formData.append("contactsIds", request.contactsIds);
				formData.append("whatsappId", request.whatsappId);
				formData.append("tagsIds", request.tagsIds);
				formData.append("log", request.log);

				if (medias)
					medias.forEach((media) => {
						formData.append("medias", media);
					});

				await api.post("/agendamento", formData, {headers: { 'content-type': 'multipart/form-data' }});
			}
			toast.success("Agendamento salvo com sucesso!");
			setDestAmount(0);
			setRequest({});
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};

	const handleChangeMedias = (e) => {
		if (!e.target.files) {
			return;
		}

		const selectedMedias = Array.from(e.target.files);
		setMedias(selectedMedias);
	};

	const handleRemoveFile = async (e) => {
		await api.delete(`/agendamento/file/${agendamentoId}`);
		setFile(null);
		toast.success("Arquivo removido");
	}

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} scroll="paper">
				<DialogTitle>
					{agendamentoId
						? `Editar agendamento ${agendamentoId}`
						: `Novo agendamento`}
				</DialogTitle>
				<Formik
					initialValues={agendamento}
					enableReinitialize={true}
					validationSchema={EnvioSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveEnvio(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent dividers>
								<ConfirmationModal
									title={"Atenção!"}
									open={confirmModalOpen}
									onClose={handleCloseConfirmationModal}
									onConfirm={handleConfirmationCreate}
								>
									Este agendamento será enviado para {destAmount} contatos, e
									levará {destAmount < 4 ? destAmount * 15 : (destAmount * 15) / 60} {destAmount < 4 ? "segundos" : "minuto(s)"} para
									ser finalizado, deseja continuar?
								</ConfirmationModal>

								<div className={classes.fieldLegend} style={{marginTop: 0}}>Whatsapp</div>
								<FormControl fullWidth margin="dense" variant="outlined" className={classes.marginTop}>
									<InputLabel>Conexão</InputLabel>
									<Select
										required
										labelWidth={60}
										value={selectedWhatsapp}
										onChange={(e) => {
											setSelectedWhatsapp(e.target.value);
											selectedWhatsapp.api === "cloud_api" ? values.message = "" : setSelectedTemplateIds([])
										}}
										MenuProps={{
											anchorOrigin: {
												vertical: "bottom",
												horizontal: "left",
											},
											transformOrigin: {
												vertical: "top",
												horizontal: "left",
											},
											getContentAnchorEl: null,
										}}
									>
										{whatsapps.map((wpp) => (
											<MenuItem key={wpp.id} value={wpp}>{wpp.api === "cloud_api" ? (<span>{wpp.name} <i> - Cloud API</i></span>) : wpp.name}</MenuItem>
										))}
									</Select>
								</FormControl>

								<div className={classes.fieldLegend}>Título</div>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label="Título"
										type="text"
										multiline
										// minRows={5}
										fullWidth
										name="title"
										error={
											touched.title && Boolean(errors.title)
										}
										helperText={
											touched.title && errors.title
										}
										variant="outlined"
										margin="dense"
									/>
								</div>

								<div className={classes.fieldLegend}>Mensagem</div>
								{selectedWhatsapp.api === "cloud_api" ? (
									<TemplateSelect
										whatsappId={selectedWhatsapp.id}
										selectedTemplateIds={selectedTemplateIds}
										onChange={values => {
											setSelectedTemplateIds(values);
										}}
									/>
								) : (
									<div className={classes.multFieldLine}>
										<Field
											as={TextField}
											label="Mensagem"
											type="text"
											multiline
											minRows={5}
											fullWidth
											name="message"
											error={
												touched.message && Boolean(errors.message)
											}
											helperText={
												touched.message && errors.message
											}
											variant="outlined"
											margin="dense"
										/>
									</div>
								)}

								<div style={{marginTop: '15px', marginBottom: '5px', fontSize: '12pt', fontWeight: 'bold'}}>Arquivo</div>
								<div className={classes.multFieldLine}>
									{file && (
										<Typography component="span" variant="body2" paragraph>
											{file}. <button type="button" onClick={handleRemoveFile}>Remover</button>
										</Typography>
									)}
								</div>
								<div className={classes.multFieldLine} style={{marginBottom: "5px"}}>
									<input
										// multiple
										type="file"
										id="upload-button"
										disabled={loading}
										className={classes.uploadInput}
										onChange={handleChangeMedias}
									/>
								</div>
								<div className={classes.textWarning}>Documentos que não são PDF abrirão um novo ticket</div>

								<div className={classes.fieldLegend}>Período de envio</div>
								<div className={classes.multFieldLine}>
									<FormControl
										style={{marginLeft: 0}}
										variant="outlined"
										className={classes.formControl}
										margin="dense"
										fullWidth
									>
										<InputLabel id="recurrence-selection-input-label">
											{"Tipo de recorrência"}
										</InputLabel>

										<Field
											as={Select}
											label={"Tipo de recorrência"}
											name="recurrence"
											labelId="recurrence-selection-label"
											id="recurrence-selection"
											required
											onChange={(e) => setValorR(e.target.value)}
											value={valorR}
										>
											<MenuItem value="once" disabled={agendamentoId && agendamento?.recurrence !== "once"}>Envio único</MenuItem>
											<MenuItem value="every_day">Todo dia</MenuItem>
											<MenuItem value="every_month">Uma vez por mês</MenuItem>
											<MenuItem value="every_week">Semanal</MenuItem>
											<MenuItem value="quarterly">Trimestral</MenuItem>
										</Field>

									</FormControl>

									<FormControl fullWidth>
										<Fragment>
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<TimePicker
													ampm={false}
													openTo="hours"
													views={["hours", "minutes"]}
													// views={["hours", "minutes", "seconds"]}
													format="HH:mm"
													// format="HH:mm:ss"
													label="Horário de envio"
													value={selectedTime}
													onChange={(date) => setTime(date)}
												/>
											</MuiPickersUtilsProvider>
										</Fragment>
									</FormControl>
								</div>
								{/*<Fragment>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											{1 == 1 ? (
												<FormControl style={{ marginLeft: 0 }}
															 variant="outlined"
															 className={classes.formControl}
															 margin="dense"
															 fullWidth
												>
													<KeyboardDatePicker
														clearable
														inputVariant="outlined"
														placeholder="20/03/2023"
														value={selectedDate}
														onChange={(date) => setDate(date)}
														minDate={new Date()}
														format="dd/MM/yyyy"
														label="Dia do envio"
														minDateMessage={"Data não pode ser anterior a hoje"}
														// maxDateMessage={"Dia do envio"}
														invalidDateMessage={"Formato de data inválido"}
													/>
												</FormControl>
											) : (
												<TimePicker
													ampm={false}
													openTo="hours"
													views={["hours", "minutes"]}
													// views={["hours", "minutes", "seconds"]}
													format="HH:mm"
													// format="HH:mm:ss"
													label="Horário envio"
													// value={selectedTime}
													// onChange={handleTimeChange}
												/>
											)}
										</MuiPickersUtilsProvider>
									</Fragment>*/}

								<div className={classes.multFieldLine}>
									{valorR === "every_week" ? (
										<FormControl
											style={{marginLeft: 0, marginRight: 0, width: '50%'}}
											variant="outlined"
											className={classes.formControl}
											margin="dense"
											fullWidth
										>
											<InputLabel id="RValue-selection-input-label">
												{"Dia da semana"}
											</InputLabel>

											<Field
												as={Select}
												label={"Dia da semana"}
												name="RValue"
												labelId="RValue-selection-label"
												id="RValue-selection"
												required
											>
												<MenuItem value="Monday">Segunda-Feira</MenuItem>
												<MenuItem value="Tuesday">Terça-Feira</MenuItem>
												<MenuItem value="Wednesday">Quarta-Feira</MenuItem>
												<MenuItem value="Thursday">Quinta-Feira</MenuItem>
												<MenuItem value="Friday">Sexta-Feira</MenuItem>
												<MenuItem value="Saturday">Sábado</MenuItem>
												<MenuItem value="Sunday">Domingo</MenuItem>
											</Field>

										</FormControl>
									) : valorR === "once" ? (
										<FormControl style={{width: '50%', marginTop: '5px'}}>
											<Fragment>
												<MuiPickersUtilsProvider utils={DateFnsUtils}>
													<KeyboardDatePicker
														clearable
														inputVariant="outlined"
														placeholder="20/03/2023"
														value={selectedTime}
														onChange={(date) => setTime(date)}
														minDate={new Date()}
														format="dd/MM/yyyy"
														label="Dia do envio"
														minDateMessage={"Data não pode ser anterior a hoje"}
														// maxDateMessage={"Dia do envio"}
														invalidDateMessage={"Formato de data inválido"}
													/>
												</MuiPickersUtilsProvider>
											</Fragment>
										</FormControl>
									) : valorR === "quarterly" ? (
										<Field style={{width: '50%'}}
											   as={TextField}
											   label={"Dia e mês de envio"}
											   name="RValue"
											// error={touched.RValue && Boolean(errors.RValue)}
											// helperText={touched.RValue && errors.RValue}
											   variant="outlined"
											   margin="dense"
											   placeholder={"05/09"}
											   fullWidth
										/>
									) : valorR === "every_day" ? null : valorR === "every_month" ? (
										<Field style={{width: '50%'}}
											   as={TextField}
											   label={"Dia do envio"}
											   name="RValue"
											// error={touched.RValue && Boolean(errors.RValue)}
											// helperText={touched.RValue && errors.RValue}
											   variant="outlined"
											   margin="dense"
											   placeholder={"05"}
											   fullWidth
										/>
									) : (
										<Field
											as={TextField}
											label={"Valor da recorrência"}
											name="RValue"
											// error={touched.RValue && Boolean(errors.RValue)}
											// helperText={touched.RValue && errors.RValue}
											variant="outlined"
											margin="dense"
											placeholder={""}
											fullWidth
										/>
									)}
								</div>
								<div className={valorR === "quarterly" || valorR === "every_month" ? classes.textWarning : classes.noDisplay}>Os envios seram feitos apenas no dia determinado</div>

								<div style={{marginTop: '15px', fontSize: '12pt', fontWeight: 'bold'}}>Destinatário(s)</div>
								<TagSelect
									disabled={!!contactId}
									selectedTagIds={selectedTagIds}
									onChange={(values) => {
										setSelectedTagIds(values);
										setSelectedContactIds([]);
										setSelectedAllContacts(false);
									}}
								/>

								<ContactSelect
									disabled={!!contactId}
									selectedContactIds={selectedContactIds}
									onChange={(selectedIds) => {
										setSelectedContactIds(selectedIds);
										setSelectedTagIds([]);
										setSelectedAllContacts(false);
									}}
								/>

								<FormGroup style={{marginLeft: '5px'}}>
									<FormControlLabel onChange={(e) => {
										// selectedAllContacts ? setSelectedAllContacts(false) : setSelectedAllContacts(true);
										// setSelectedContactIds([]);
										// setSelectedTagIds([]);
									}} control={<Switch
										disabled={!!contactId}
										checked={selectedAllContacts}
										onChange={(e) => {
											setSelectedAllContacts(e.target.checked);
											setSelectedContactIds([]);
											setSelectedTagIds([]);
										}}
										inputProps={{'aria-label': 'controlled'}}
									/>} label="Todos os contatos"/>
								</FormGroup>
								<div className={classes.textWarning}>Não recomendamos o agendar mensagens
									para todos os contatos, pois a mensagem pode ser interpretada como Span pela
									Meta/Facebook e bloquear o número!
								</div>
								{agendamentoId && (
									<>
										<FormGroup style={{marginLeft: '5px'}}>
											<FormControlLabel control={<Switch
												checked={reenviar}
												onChange={(e) => {
													setReenviar(e.target.checked);
												}}
												inputProps={{'aria-label': 'controlled'}}
											/>} label="Reenviar agendamento"/>
										</FormGroup>
										<div className={classes.textWarning}>
											Ao editar, caso ainda queira que o agendamento seja enviado, marque para reenvia-lo!
										</div>
									</>
								)}
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									Cancelar
								</Button>
								<ButtonWithSpinner
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									loading={loading}
									className={classes.btnWrapper}
									size="small"
								>
									{agendamentoId
										? `Salvar`
										: `Enviar`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</ButtonWithSpinner>
								{/*<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{agendamentoId
										? `Salvar`
										: `Enviar`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>*/}
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default AgendamentoModal;